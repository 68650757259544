<template>
	<v-tooltip :left="!toolbar" :bottom="toolbar">
		<template v-slot:activator="{ on }">
			<v-btn
				v-if="hasModule"
				:fixed="!toolbar"
				:bottom="!toolbar"
				:medium="!toolbar"
				:right="!toolbar"
				:fab="!toolbar"
				:icon="toolbar"
				:large="toolbar"
				:color="toolbar ? '' : 'primary'"
				v-on="on"
				@click="toggleChatSupport"
			>
				<v-icon :class="toolbar ? '' : 'material-icons-two-tone'" :color="toolbar ? 'primary' : ''" large>support_agent</v-icon>
			</v-btn>
		</template>
		<span v-t="'support'" />
	</v-tooltip>
</template>

<script>
import SupportModuleGuard from '@/mixins/ModulesGuards/Support/SupportModuleGuard'

export default {
	name: 'SupportChatButton',
	mixins: [SupportModuleGuard],
	props: {
		toolbar: {
			required: false,
			type: Boolean,
			default: false
		}
	},
	methods: {
		toggleChatSupport() {
			this.appEventBus.emit(this.appEvents.TOGGLE_SUPPORT_CHAT)
		},
	}
}
</script>
